import { AxiosCompatibleResponse } from '../http/types';
import http from '../http';

import {
  ExploreProductsParams,
  ExploreProductsResponse,
  ExploreStyleEditProductsParams,
  ExploreStyleEditProductsResponse,
} from './types';

import {
  EXPLORE_ENDPOINT,
  EXPLORE_STYLE_EDIT_ENDPOINT,
} from '@/constants/endpoints';

export function getExploreProducts(
  params: ExploreProductsParams,
  withAuth?: boolean
): Promise<AxiosCompatibleResponse<ExploreProductsResponse>> {
  return http.get(EXPLORE_ENDPOINT, { params, withAuth });
}

export async function fetchExploreStyleEditProducts(
  params: ExploreStyleEditProductsParams
): Promise<AxiosCompatibleResponse<ExploreStyleEditProductsResponse>> {
  return http.get(EXPLORE_STYLE_EDIT_ENDPOINT, { params, withAuth: true });
}
