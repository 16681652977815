import { PRODUCT_BRANDED_CATEGORIES_LOCAL_STORAGE_KEY } from '@/modules/product/constants';
import { getLocalStorageItem } from '@/modules/storage';
import { safeJsonParse } from '@/modules/storage/helpers';

export function getBrandCategoryListFromLocalStorage() {
  const brandedCategoriesJSON = getLocalStorageItem(
    PRODUCT_BRANDED_CATEGORIES_LOCAL_STORAGE_KEY
  );

  return safeJsonParse(brandedCategoriesJSON, []) as string[];
}
