import { createHash } from 'crypto';

import React, { Dispatch } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteCookie, setCookie } from 'cookies-next';
import { useSearchParams } from 'next/navigation';

import { useExtendedRouter } from '../routing/useExtendedRouter';

import {
  AccountLinkingCredential,
  LoginCredentials,
  LoginResponse,
  LoginType,
  MfaRequiredResponse,
} from './types';

import { login, loginWithApple } from '@/modules/login/api';
import {
  ACCESS_TOKEN_KEY,
  HASHED_USER_EMAIL_KEY,
  USER_ID_KEY,
} from '@/constants/cookies';
import {
  AxiosCompatibleError,
  AxiosCompatibleResponse,
} from '@/modules/http/types';
import { RQ_USER_DATA_KEY } from '@/modules/ReactQuery/cacheKeys';
import { identify } from '@/modules/auth/api';
import {
  COOKIE_ACCOUNT_LINKING_MFA,
  COOKIE_ACCOUNT_LINKING_TOKEN_OPTIONS,
  COOKIE_MFA_TOKEN,
  COOKIE_MFA_TOKEN_OPTS,
  GENERIC_ERROR,
} from '@/modules/auth/constants';
import { syncGuestBag } from '@/modules/bag/helpers';
import { useLoginTracking } from '@/modules/login/useLoginTracking';
import {
  deleteAccountLinkingCookies,
  handleAccountLinking,
  validateRedirect,
} from '@/modules/login/helpers';

type Props = {
  setError?: Dispatch<React.SetStateAction<string>>;
  loginType: LoginType;
  handleSSOError?: (
    credential: LoginCredentials,
    errorData?: { error: string; errorMessage: string }
  ) => void;
  accountLinkingCredential?: AccountLinkingCredential | null;
  useDefaultAppleErrorHandling?: boolean;
  storedSSOEmail?: string;
};

export function useLoginMutations({
  setError,
  loginType,
  handleSSOError,
  accountLinkingCredential,
  useDefaultAppleErrorHandling = true,
  storedSSOEmail,
}: Props) {
  const {
    sendLoginEvents,
    sendLoginFailureEvents,
    sendLinkAccountSuccessEvent,
  } = useLoginTracking();
  const queryClient = useQueryClient();
  const redirect = useSearchParams().get('redirect') || '';

  const router = useExtendedRouter();

  async function handleLoginSuccess(token: string) {
    setCookie(ACCESS_TOKEN_KEY, token);

    deleteCookie(COOKIE_MFA_TOKEN, COOKIE_MFA_TOKEN_OPTS);

    const { data: user } = await queryClient.fetchQuery({
      queryKey: [RQ_USER_DATA_KEY],
      queryFn: identify,
    });

    setCookie(USER_ID_KEY, user.id);

    setCookie(
      HASHED_USER_EMAIL_KEY,
      createHash('sha256').update(user.email.toString(), 'utf8').digest('hex')
    );

    const emailsMatch = storedSSOEmail === user.email;

    await Promise.allSettled([
      ...(emailsMatch
        ? [
            handleAccountLinking(
              accountLinkingCredential,
              sendLinkAccountSuccessEvent
            ),
          ]
        : []),
      syncGuestBag(user.id),
      sendLoginEvents(user, loginType),
    ]);

    if (accountLinkingCredential) {
      deleteAccountLinkingCookies();
    }

    const validatedRedirect = validateRedirect(redirect, user);

    try {
      window.location.href = validatedRedirect;
    } catch {
      window.location.href = '/';
    }
  }

  async function redirectToMFA(mfaToken: string) {
    setCookie(COOKIE_MFA_TOKEN, mfaToken, COOKIE_MFA_TOKEN_OPTS);

    // @PB TODO ADD WHEN ACCOUNT LINKING IS READY
    if (accountLinkingCredential) {
      setCookie(
        COOKIE_ACCOUNT_LINKING_MFA,
        JSON.stringify(accountLinkingCredential),
        COOKIE_ACCOUNT_LINKING_TOKEN_OPTIONS
      );
    }

    router.push(
      `/login/mfa${redirect ? `?redirect=${encodeURIComponent(redirect)}` : ''}`
    );
  }

  function onSuccess(response: AxiosCompatibleResponse<LoginResponse>) {
    return handleLoginSuccess(response.data.token);
  }

  type ErrorMessage = { error: string; error_message: string };

  /** @PB TODO type the error as unknown */
  async function onError(
    error: AxiosCompatibleError<MfaRequiredResponse | ErrorMessage>,
    credential: LoginCredentials
  ) {
    const maybeErrorBody = error?.response?.data;

    if (
      maybeErrorBody &&
      'mfa_token' in maybeErrorBody &&
      error?.response?.status === 403 &&
      maybeErrorBody?.error === 'MFA_REQUIRED'
    ) {
      return redirectToMFA(maybeErrorBody?.mfa_token);
    }

    sendLoginFailureEvents(loginType);

    if (!setError) {
      return;
    }

    if (handleSSOError) {
      const errorData =
        maybeErrorBody && !('mfa_token' in maybeErrorBody)
          ? {
              error: maybeErrorBody.error,
              errorMessage: maybeErrorBody.error_message,
            }
          : undefined;

      return handleSSOError(credential, errorData);
    }

    const message = (maybeErrorBody as ErrorMessage)?.error_message as string;

    return setError(message || GENERIC_ERROR);
  }

  const appleLoginMutation = useMutation({
    mutationFn: loginWithApple,
    onSuccess,
    onError: (error, variables) => {
      if (useDefaultAppleErrorHandling) {
        return onError(
          error as unknown as AxiosCompatibleError<
            MfaRequiredResponse | ErrorMessage
          >,
          variables
        );
      }
    },
  });

  const loginMutation = useMutation({
    mutationFn: login,
    onSuccess,
    onError,
  });

  return { appleLoginMutation, loginMutation };
}
