import React from 'react';
import { useTranslations } from 'next-intl';
import { LoadingBall } from '@depop/web-ui-kit/LoadingBall';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';

import { RecentlyViewedProductCard } from '@/components/RecentlyViewed/RecentlyViewedProductCard';
import { InfiniteScroll } from '@/components/InfiniteScroll';
import { ErrorMessage } from '@/components/ErrorMessage';
import { useRecentlyViewedTracking } from '@/modules/recentlyViewed/hooks/useRecentlyViewedTracking';
import {
  LocallyStoredRecentlyViewedProduct,
  RecentlyViewedProduct,
} from '@/modules/recentlyViewed/types';

type Props = {
  productId?: number;
  isLoading: boolean;
  isError: boolean;
  onEndReached: () => void;
  isFetchingNextPage: boolean;
  hasMore: boolean;
  products: (RecentlyViewedProduct | LocallyStoredRecentlyViewedProduct)[];
  moduleOrigin?: string;
};

export function RecentlyViewedModalProducts({
  productId,
  isLoading,
  isError,
  onEndReached,
  isFetchingNextPage,
  hasMore,
  products,
  moduleOrigin,
}: Props) {
  const tCommon = useTranslations('common');
  const { sendRecentlyViewedItemsInteractionAction } =
    useRecentlyViewedTracking(productId);

  function handleProductClick(selectedProductId: number) {
    sendRecentlyViewedItemsInteractionAction(
      'RecentlyViewedItemsModuleProductClickedInModal',
      selectedProductId
    );
  }

  if (isLoading) {
    return <LoadingBall />;
  }

  if (isError) {
    return (
      <ErrorMessage type="body" className={styles.error}>
        {tCommon('500.Message')}
      </ErrorMessage>
    );
  }

  if (!products.length) {
    return <Text>{tCommon('NoRecentlyViewedItems')}</Text>;
  }

  return (
    <InfiniteScroll
      onEndReached={onEndReached}
      hasMore={hasMore}
      loading={isFetchingNextPage}
      LoadingComponent={<LoadingBall />}
    >
      <ul className={styles.productList}>
        {products.map((product) => (
          <li key={product.id}>
            <RecentlyViewedProductCard
              product={product}
              handleClick={(product) => handleProductClick(product.id)}
              moduleOrigin={moduleOrigin}
            />
          </li>
        ))}
      </ul>
    </InfiniteScroll>
  );
}
