import { infiniteQueryOptions } from '@tanstack/react-query';

import {
  RQ_SEARCH_EXPLORE,
  RQ_SEARCH_EXPLORE_STYLE_EDIT,
} from '../ReactQuery/cacheKeys';

import { fetchExploreStyleEditProducts, getExploreProducts } from './api';
import { ExploreProductsParams, ExploreStyleEditProductsParams } from './types';

type ExploreInfiniteQueryOptionArgs = ExploreProductsParams & {
  initialLimit?: number;
  withAuth?: boolean;
};

export function exploreInfiniteQueryOption({
  limit,
  country,
  force_fee_calculation,
  withAuth,
}: ExploreInfiniteQueryOptionArgs) {
  return infiniteQueryOptions({
    queryKey: [RQ_SEARCH_EXPLORE],
    queryFn: async ({ pageParam }) =>
      await getExploreProducts(
        {
          ...pageParam,
          force_fee_calculation,
        },
        withAuth
      ),
    initialPageParam: { limit, country },
    getNextPageParam: ({
      data: {
        meta: { last_offset_id },
      },
    }) => {
      return { limit, country, cursor: last_offset_id };
    },
  });
}

export function exploreStyleEditInfiniteQueryOptions({
  limit,
  country,
  lang,
  force_fee_calculation,
}: ExploreStyleEditProductsParams) {
  return infiniteQueryOptions({
    queryKey: [RQ_SEARCH_EXPLORE_STYLE_EDIT],
    queryFn: ({ pageParam }) =>
      fetchExploreStyleEditProducts({
        country,
        lang,
        limit,
        cursor: pageParam,
        force_fee_calculation,
      }),
    initialPageParam: '',
    getNextPageParam: (lastPage) => {
      const { cursor, end } = lastPage.data.meta;
      return !end && cursor ? cursor : undefined;
    },
  });
}
