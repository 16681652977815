import { useInfiniteQuery } from '@tanstack/react-query';

import { exploreInfiniteQueryOption } from '../queries';
import { FETCH_EXPLORE_PRODUCTS_LIMIT } from '../constants';

import { useCurrentLocation } from '@/modules/location/useCurrentLocation';
import { LocationsEnabled } from '@/modules/location/constants';
import { useExperiments } from '@/modules/experiments/useExperiments';
import { VARIANT_IDENTIFIER } from '@/modules/experiments/config';
import { useCurrentUser } from '@/modules/user/useCurrentUser';

export function useExploreProducts() {
  const { location } = useCurrentLocation();
  const { web_upfront_fees } = useExperiments(['web_upfront_fees']);
  const [isAuth] = useCurrentUser();
  return useInfiniteQuery(
    exploreInfiniteQueryOption({
      limit: FETCH_EXPLORE_PRODUCTS_LIMIT,
      country: location as keyof LocationsEnabled,
      force_fee_calculation: web_upfront_fees === VARIANT_IDENTIFIER,
      withAuth: isAuth,
    })
  );
}
