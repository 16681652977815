'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { useResponsive } from '@depop/web-ui-kit/useResponsive';

import styles from './styles.module.css';

import { ModuleHeader, ModuleHeaderSize } from '@/components/ModuleHeader';
import { ExtendedLink } from '@/components/ExtendedLink';
import {
  ActivityTrackerEventType,
  HomePageModuleNames,
  ModuleInteractionType,
} from '@/modules/activityTracker/constants';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { BuyerOfferDisplayStatus } from '@/modules/offers/types';
import { OfferTile } from '@/components/OfferTile';
import { useBuyersActiveOffers } from '@/modules/offers/hooks/useBuyersActiveOffers';
import { getActiveOffers } from '@/modules/offers/helpers/getActiveOffers';
import {
  ACTIVE_OFFERS_LG_SCREEN_MAX_ITEMS,
  ACTIVE_OFFERS_MD_SCREEN_MAX_ITEMS,
} from '@/app/[locale]/(home)/components/ActiveOffers/constants';

export function ActiveOffersUI() {
  const tOffers = useTranslations('offers');
  const tCommon = useTranslations('common');
  const [_, currentUser] = useCurrentUser();
  const { isMinLarge, isMinMedium } = useResponsive();
  const { sendActivityTrackerEvent } = useActivityTracker({
    userId: currentUser?.id,
  });

  const { data: offersData, refetch } = useBuyersActiveOffers();
  const activeOffers =
    (offersData?.pages && getActiveOffers(offersData.pages[0])) || [];

  function handleSeeMoreClick() {
    sendActivityTrackerEvent(
      ActivityTrackerEventType.MODULE_INTERACTION_ACTION,
      {
        moduleName: HomePageModuleNames.ActiveOffers,
        interactionType: ModuleInteractionType.MakeOfferHomePageSeeMore,
        schemaVersion: '1.0',
      }
    );
  }

  const isMediumScreen = isMinMedium && !isMinLarge;
  const hasDismissibleOffer = activeOffers.some(
    (offer) =>
      offer.offer_display_status ===
      BuyerOfferDisplayStatus.RECEIVED_PRIVATE_OFFER
  );

  const visibleActiveOffers = activeOffers.slice(
    0,
    isMediumScreen
      ? ACTIVE_OFFERS_MD_SCREEN_MAX_ITEMS
      : ACTIVE_OFFERS_LG_SCREEN_MAX_ITEMS
  );

  return (
    <section>
      <ModuleHeader
        title={tOffers('YourActiveOffers')}
        size={ModuleHeaderSize.Large}
      >
        <ExtendedLink href="/messages/offers/" onClick={handleSeeMoreClick}>
          <Text className={styles.seeMoreText}>{tCommon('SeeMore')}</Text>
        </ExtendedLink>
      </ModuleHeader>

      <ul className={styles.activeOffersContainer}>
        {visibleActiveOffers.map((offerItem) => (
          <li key={offerItem.offer_id} className={styles.activeOfferListItem}>
            <OfferTile
              {...offerItem}
              showInlineButtons
              includeBreakpointStyles={false}
              showImageMarginTop={hasDismissibleOffer}
              refetchParentQuery={refetch}
            />
          </li>
        ))}
      </ul>
    </section>
  );
}
