'use client';

import React, { useEffect } from 'react';
import Script from 'next/script';

import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';
import { MazeScript } from '@/modules/thirdParty/maze';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { useGoogleSignInPrompt } from '@/modules/auth/useGoogleSignInPrompt';

type Props = {
  nonce?: string;
};

export function InitHome({ nonce = '' }: Props) {
  const [, user] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({ userId: user?.id });

  useGoogleSignInPrompt({ nonce });

  useEffect(() => {
    sendActivityTrackerEvent(ActivityTrackerEventType.HOMEPAGE_VIEW);
  }, []);

  return (
    <Script id="maze-loader" nonce={nonce} strategy="lazyOnload">
      {MazeScript}
    </Script>
  );
}
