import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import { Button } from '@depop/web-ui-kit/Button';
import { H2 } from '@depop/web-ui-kit/Typography/H2';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import { ExtendedLink } from '../ExtendedLink';

import styles from './styles.module.css';

import { appendParams } from '@/modules/routing/appendParams/appendParams';

type BannerProps = {
  title: string;
  body: Document;
  cta: {
    copy: string;
    url: string;
    onClick?: () => void;
  };
  desktopBackgroundImage: string;
  mobileBackgroundImage: string;
};
export function Banner({
  title,
  body,
  cta: { copy, url, onClick },
  desktopBackgroundImage,
  mobileBackgroundImage,
}: BannerProps) {
  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <H2 className={styles.heading}>
          <span>{title}</span>
        </H2>
        {documentToReactComponents(body, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (_, children) => (
              <Text className={styles.bodyParagraph}>{children}</Text>
            ),
          },
        })}
        <div className={styles.ctaWrapper}>
          <ExtendedLink passHref href={appendParams(url)} legacyBehavior>
            <Button
              outline
              as="a"
              onClick={onClick}
              className={styles.bannerButton}
            >
              {copy}
            </Button>
          </ExtendedLink>
        </div>
      </div>
      <picture>
        <source
          media="(min-width: 768px)"
          srcSet={`${desktopBackgroundImage}?fm=webp&w=1400`}
        />
        <img
          className={styles.bannerImage}
          src={mobileBackgroundImage}
          alt={title}
        />
      </picture>
    </div>
  );
}
