'use client';

import React from 'react';
import { H3 } from '@depop/web-ui-kit/Typography/H3';
import { useResponsive } from '@depop/web-ui-kit/useResponsive';
import { Carousel } from '@depop/web-ui-kit/Carousel';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';

import { PopularThisWeekData } from '@/modules/cms/helpers/assertions/popularThisWeek.ts';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker.ts';
import { ModuleHeader, ModuleHeaderSize } from '@/components/ModuleHeader';
import {
  ActivityTrackerEventType,
  HomePageModuleNames,
  moduleOriginParamName,
} from '@/modules/activityTracker/constants.ts';
import { ExtendedLink } from '@/components/ExtendedLink';
import { appendParams } from '@/modules/routing/appendParams/appendParams.ts';
import { ScrollableItemRow } from '@/components/ScrollableItemRow';

type Props = {
  data: PopularThisWeekData;
};

type Item = {
  subtitle: string;
  title: string;
  link: string;
  image: {
    url: string;
    width: number;
    height: number;
  };
};

const ITEMS_PER_SLIDE = {
  xs: 2,
  sm: 2,
  md: 4,
  lg: 4,
  xl: 4,
};

function getProductToDisplay(
  item: Item,
  index: number,
  sendATEvent: (index: number, title: string) => void
) {
  const { title, subtitle, link, image } = item;
  const [amount, ...restOfSalesInfo] = subtitle.split(' ');
  const searchesText = restOfSalesInfo.join(' ');

  if (!title) {
    return null;
  }

  return (
    <ExtendedLink
      className={styles.link}
      key={`${item.title?.slice(0, 4)}_${index}`}
      href={appendParams(link, [
        moduleOriginParamName,
        HomePageModuleNames.PopularThisWeek,
      ])}
      onClick={() => {
        sendATEvent(index, title);
      }}
    >
      <div className={styles.imgContainer}>
        <img
          src={`${image.url}?w=500&fm=webp`}
          width={image.width}
          height={image.height}
          alt=""
        />
      </div>
      <div className={styles.textContainer}>
        <H3 className={styles.title}>{title}</H3>
        <Text className={styles.text}>
          <b>{amount} </b>
          {searchesText}
        </Text>
      </div>
    </ExtendedLink>
  );
}

export function PopularThisWeekUi({ data }: Props) {
  const { isMaxMedium } = useResponsive();
  const { sendActivityTrackerEvent } = useActivityTracker();

  function sendATEvent(index: number, title: string) {
    sendActivityTrackerEvent(ActivityTrackerEventType.MODULE_CLICK_ACTION, {
      moduleName: HomePageModuleNames.PopularThisWeek,
      itemId: -1,
      itemClicked: title,
      itemPosition: index,
      schemaVersion: '1.0',
    });
  }

  const carouselItems = data.items.map((item, index) =>
    getProductToDisplay(item, index, sendATEvent)
  );

  return (
    <section>
      <ModuleHeader title={data.titleCopy} size={ModuleHeaderSize.Large} />
      {isMaxMedium ? (
        <ScrollableItemRow
          itemsPerRow={carouselItems.length}
          minItemWidth={256}
        >
          {carouselItems}
        </ScrollableItemRow>
      ) : (
        <Carousel
          id="popularThisWeek__carousel"
          items={carouselItems}
          itemsPerSlide={ITEMS_PER_SLIDE}
        />
      )}
    </section>
  );
}
