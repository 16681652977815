import { useCurrentUser } from '@/modules/user/useCurrentUser.ts';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker.ts';
import {
  ActivityTrackerEventType,
  HomePageModuleNames,
  ModuleInteractionType,
} from '@/modules/activityTracker/constants.ts';
import { useCurrentRoute } from '@/modules/activityTracker/useCurrentRoute.ts';

export function useRecentlyViewedTracking(productId?: number) {
  const [, currentUser] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({
    userId: currentUser?.id,
  });
  const currentRoute = useCurrentRoute();

  function sendModuleInteractionAction() {
    sendActivityTrackerEvent(
      ActivityTrackerEventType.MODULE_INTERACTION_ACTION,
      {
        moduleName: HomePageModuleNames.RecentlyViewed,
        interactionType: ModuleInteractionType.SeeMoreClicked,
        schemaVersion: '1.0',
      }
    );
  }

  function sendInteractionAction(interactionType: string) {
    sendActivityTrackerEvent(
      ActivityTrackerEventType.RECENTLY_VIEWED_ITEMS_INTERACTION_ACTION,
      {
        interactionType,
        productPageId: productId,
        schemaVersion: '3.0',
      }
    );
  }

  function sendModuleClickAction(
    itemId: number,
    itemClicked: string,
    itemPosition: number
  ) {
    sendActivityTrackerEvent(ActivityTrackerEventType.MODULE_CLICK_ACTION, {
      itemClicked,
      itemId,
      itemPosition,
      moduleName: HomePageModuleNames.RecentlyViewed,
      schemaVersion: '1.0',
    });
  }

  function sendRecentlyViewedItemsInteractionAction(
    interactionType: string,
    selectedProductId?: number
  ) {
    sendActivityTrackerEvent(
      ActivityTrackerEventType.RECENTLY_VIEWED_ITEMS_INTERACTION_ACTION,
      {
        interactionType,
        selectedProductId,
        productPageId: productId,
        schemaVersion: '3.0',
      }
    );
  }

  function sendModuleScrollAction() {
    sendActivityTrackerEvent(ActivityTrackerEventType.MODULE_SCROLL_ACTION, {
      module: 'RecentlyViewedModal',
      page: currentRoute,
    });
  }

  function sendModuleView() {
    sendActivityTrackerEvent(ActivityTrackerEventType.MODULE_VIEW, {
      module: 'RecentlyViewedCarousel',
      page: currentRoute,
      pageId: productId?.toString(),
    });
  }

  return {
    sendModuleInteractionAction,
    sendInteractionAction,
    sendModuleClickAction,
    sendModuleScrollAction,
    sendModuleView,
    sendRecentlyViewedItemsInteractionAction,
  };
}
