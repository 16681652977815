import { useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';

import { getLocallyStoredRecentlyViewedProducts } from '@/modules/recentlyViewed/helpers';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { RQ_RECENTLY_VIEWED } from '@/modules/ReactQuery/cacheKeys';
import {
  fetchRecentlyViewed,
  updateRecentlyViewed,
} from '@/modules/recentlyViewed/api';
import { removeLocalStorageItem } from '@/modules/storage';
import { RECENTLY_VIEWED_PRODUCTS_LOCAL_STORAGE_KEY } from '@/modules/recentlyViewed/constants';
import {
  LocallyStoredRecentlyViewedProduct,
  UpdateRecentlyViewedRequestBody,
} from '@/modules/recentlyViewed/types';
import { useExperiments } from '@/modules/experiments/useExperiments';
import { VARIANT_IDENTIFIER } from '@/modules/experiments/config';

export function useRecentlyViewedProducts(productId?: number, limit?: number) {
  const [locallyStoredProducts, setLocallyStoredProducts] = useState<
    LocallyStoredRecentlyViewedProduct[]
  >([]);
  const hasLocallyStoredProducts = locallyStoredProducts.length > 0;
  const [isAuthenticated, currentUser] = useCurrentUser();
  const { web_upfront_fees } = useExperiments(['web_upfront_fees']);
  const userId = currentUser?.id;

  useEffect(() => {
    // We have to set this to state to avoid reading from local storage on the server,
    // which causes hydration errors.
    setLocallyStoredProducts(getLocallyStoredRecentlyViewedProducts());
  }, []);

  const updateRecentlyViewedMutation = useMutation({
    mutationFn: (args: {
      userId: number;
      requestBody: UpdateRecentlyViewedRequestBody;
    }) => updateRecentlyViewed(args),
    onSuccess: () => {
      // Clear local storage after syncing with the user's account.
      // If syncing fails, local products will remain in storage until the next successful sync.
      removeLocalStorageItem(RECENTLY_VIEWED_PRODUCTS_LOCAL_STORAGE_KEY);
    },
    retry: 1,
  });

  function isRecentlyViewedQueryEnabled() {
    const isMutationSuccessOrError =
      updateRecentlyViewedMutation.isSuccess ||
      updateRecentlyViewedMutation.isError;

    if (!isAuthenticated) {
      return false;
    }
    return hasLocallyStoredProducts
      ? // Enable the query once local products have been synced with the user's account.
        isMutationSuccessOrError
      : // Or if there are no local products, just enable the query.
        true;
  }

  const recentlyViewedQuery = useInfiniteQuery({
    queryKey: [RQ_RECENTLY_VIEWED],
    queryFn: ({ pageParam }) =>
      fetchRecentlyViewed({
        params: {
          ...(pageParam ? { offset_id: pageParam } : undefined),
          limit,
          force_fee_calculation: web_upfront_fees === VARIANT_IDENTIFIER,
        },
      }),
    initialPageParam: '',
    getNextPageParam: (lastPage) => {
      const { end, last_offset_id } = lastPage.data.meta;
      return !end && last_offset_id ? last_offset_id : null;
    },
    enabled: isRecentlyViewedQueryEnabled(),
  });

  const userProducts =
    recentlyViewedQuery?.data?.pages.flatMap((page) => page.data.products) ||
    [];
  const localOrUserProducts = isAuthenticated
    ? userProducts
    : locallyStoredProducts;
  const filteredProducts = localOrUserProducts
    .filter((product) => product.id !== productId)
    .filter((product) => !product.sold);

  useEffect(() => {
    if (
      userId &&
      hasLocallyStoredProducts &&
      updateRecentlyViewedMutation.isIdle
    ) {
      // Sync local products with the user's account.
      updateRecentlyViewedMutation.mutate({
        userId,
        requestBody: {
          views: locallyStoredProducts.map((product) => ({
            productId: product.id,
            timestamp: product.timestamp,
          })),
        },
      });
    }
  }, [userId, hasLocallyStoredProducts, updateRecentlyViewedMutation.isIdle]);

  function isLoading() {
    const isMutationIdleOrPending =
      updateRecentlyViewedMutation.isIdle ||
      updateRecentlyViewedMutation.isPending;

    if (!isAuthenticated) {
      return false;
    }
    return hasLocallyStoredProducts
      ? // Local products are being synced with the user's account.
        isMutationIdleOrPending
      : // Or the user's recently viewed products are being fetched.
        recentlyViewedQuery.isLoading;
  }

  return {
    products: filteredProducts,
    isLoading: isLoading(),
    isError:
      updateRecentlyViewedMutation.isError || recentlyViewedQuery.isError,
    fetchNextPage: recentlyViewedQuery.fetchNextPage,
    hasNextPage: recentlyViewedQuery.hasNextPage,
    isFetchingNextPage: recentlyViewedQuery.isFetchingNextPage,
  };
}
