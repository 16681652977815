import React from 'react';
import { setCookie } from 'cookies-next';

import {
  EMAIL_SIGN_UP_DEFAULT_FORM_VALUES,
  SIGNUP_FORM_ROUTES,
  SSO_SIGN_UP_CREDENTIALS_SESSION_STORAGE_KEY,
} from '../constants';
import { SignupType } from '../types';

import { SSOCredentials, isAppleCredentials } from '@/modules/login/types';
import {
  COOKIE_ACCOUNT_LINKING_TOKEN_APPLE,
  COOKIE_ACCOUNT_LINKING_TOKEN_GOOGLE,
  COOKIE_ACCOUNT_LINKING_TOKEN_OPTIONS,
} from '@/modules/auth/constants';
import { useExtendedRouter } from '@/modules/routing/useExtendedRouter';
import { setSessionStorageItem } from '@/modules/storage';
import { getEmailAvailabilityStatus } from '@/modules/auth/helpers';
import { EmailStatus } from '@/modules/auth/types';
import { useRedirectQueryParam } from '@/modules/auth/useRedirectQueryParam';

type Props = {
  ssoType: SignupType;
  setError: React.Dispatch<React.SetStateAction<string>>;
};

export function useSSOSignup({ ssoType, setError }: Props) {
  const redirectQueryParam = useRedirectQueryParam();
  const router = useExtendedRouter();
  const signupFormPath =
    SIGNUP_FORM_ROUTES[ssoType.toLowerCase() as keyof typeof SIGNUP_FORM_ROUTES]
      .path;

  async function signupWithSSO({
    credentials,
    email,
    name,
  }: {
    email: string;
    credentials: SSOCredentials;
    name?: {
      firstName: string;
      lastName: string;
    };
  }) {
    const status = await getEmailAvailabilityStatus(email, setError);

    if (status === EmailStatus.UNAVAILABLE) {
      if (isAppleCredentials(credentials)) {
        setCookie(
          COOKIE_ACCOUNT_LINKING_TOKEN_APPLE,
          JSON.stringify({
            appleId: credentials.appleUserId,
            appleAccessToken: credentials.appleAccessToken,
          }),
          COOKIE_ACCOUNT_LINKING_TOKEN_OPTIONS
        );
      } else {
        setCookie(
          COOKIE_ACCOUNT_LINKING_TOKEN_GOOGLE,
          JSON.stringify(credentials),
          COOKIE_ACCOUNT_LINKING_TOKEN_OPTIONS
        );
      }

      return router.push(`/login/${redirectQueryParam}`);
    }

    setSessionStorageItem(
      SSO_SIGN_UP_CREDENTIALS_SESSION_STORAGE_KEY,
      JSON.stringify({
        ...EMAIL_SIGN_UP_DEFAULT_FORM_VALUES,
        email,
        ...(name?.firstName && { firstName: name.firstName }),
        ...(name?.lastName && { lastName: name.lastName }),
        ...credentials,
      })
    );

    return router.push(`${signupFormPath}${redirectQueryParam}`);
  }

  return {
    signupWithSSO,
  };
}
