import { LazyLoadImage } from '@depop/web-ui-kit/LazyLoadImage';
import { VideoBasicBoldIcon } from '@depop/web-ui-kit/Icons/VideoBasicBoldIcon';
import React from 'react';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { useTranslations } from 'next-intl';

import styles from './styles.module.css';

import {
  ProductCard,
  Props as ProductCardProps,
} from '@/components/ProductCard';
import { getProductHoverImgSrc } from '@/modules/product/helpers/getProductHoverImgSrc.ts';
import { Price } from '@/components/Price';
import { Preview } from '@/modules/product/types.ts';
import { TransformedPriceEstimateDictionary } from '@/modules/price/types.ts';

type Props = ProductCardProps & {
  pictures: Preview[] | undefined;
  pricing: TransformedPriceEstimateDictionary;
  hasVideo: boolean;
  size?: string | null;
};

export function HomepageProductCard({
  pictures,
  hasVideo,
  pricing,
  size,
  ...props
}: Props) {
  const tCommon = useTranslations('common');
  const hasMoreThanOnePicture = Boolean(pictures && pictures.length > 1);

  const conditionalProps: Partial<ProductCardProps> = {};
  if (hasMoreThanOnePicture) {
    conditionalProps.HoverOverlay = () => (
      <LazyLoadImage alt="" src={pictures && getProductHoverImgSrc(pictures)} />
    );
  }

  return (
    <ProductCard
      ProductAttributes={() => (
        <div className={styles.productAttributesContainer}>
          <Price
            currencyCode={pricing.currency_name}
            price={pricing.original_price.total_price}
            discountedPrice={pricing.discounted_price?.total_price}
            inPreview
          />

          {size && <Text aria-label={tCommon('Size')}>{size}</Text>}
        </div>
      )}
      Overlay={() =>
        hasVideo ? (
          <div className={styles.videoIcon}>
            <VideoBasicBoldIcon />
          </div>
        ) : null
      }
      {...props}
      {...conditionalProps}
    />
  );
}
