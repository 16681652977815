'use client';

import React from 'react';
import { H2 } from '@depop/web-ui-kit/Typography/H2';
import { Button } from '@depop/web-ui-kit/Button';
import ReactDOM from 'react-dom';

import styles from './styles.module.css';

import { WwmwData } from '@/modules/cms/helpers/assertions/wwmw';
import { ExtendedLink } from '@/components/ExtendedLink';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';

type MenswearWomenswearTilesUIProps = {
  tilesData: WwmwData;
};

export function MenswearWomenswearTilesUi({
  tilesData,
}: MenswearWomenswearTilesUIProps) {
  const { sendActivityTrackerEvent } = useActivityTracker();

  function handleTileClick(title: string, position: number): void {
    sendActivityTrackerEvent(ActivityTrackerEventType.MODULE_CLICK_ACTION, {
      itemClicked: `homepage_${title.toLowerCase()}_tile`,
      itemId: -1,
      itemPosition: position,
      moduleName: 'homepage_mw_ww_tiles',
    });
  }

  return (
    <section className={styles.container}>
      {tilesData.items.map(
        (
          { title, link: href, subtitle, image: { width, height, url: src } },
          index
        ) => {
          ReactDOM.preload(src, { as: 'image', fetchPriority: 'high' });

          return (
            <ExtendedLink
              className={styles.link}
              href={`${href}?moduleOrigin=homepage_${title.toLowerCase()}_tile`}
              key={`title-${index}`}
              onClick={() => handleTileClick(title, index)}
            >
              <img
                className={styles.image}
                src={src}
                alt=""
                height={height}
                width={width}
              />
              <div className={styles.titleButtonContainer}>
                <H2 className={styles.title}>{title}</H2>
                <Button className={styles.button}>{subtitle}</Button>
              </div>
            </ExtendedLink>
          );
        }
      )}
    </section>
  );
}
