'use client';

import React from 'react';
import clsx from 'clsx';
import { Carousel } from '@depop/web-ui-kit/Carousel';

import styles from './styles.module.css';

import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { Banner } from '@/components/Banner';
import { TransformedWebBannerResponse } from '@/modules/cms/types';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useShowHomepageSearchBar } from '@/modules/home/hooks/useShowHomepageSearchBar';

type Props = {
  webBannerData: TransformedWebBannerResponse[];
};

export function MarketingCarouselUi({ webBannerData }: Props) {
  const { sendActivityTrackerEvent } = useActivityTracker();
  const [isAuthenticated] = useCurrentUser();
  const isHomepageSearchBarVisible = useShowHomepageSearchBar();

  function onClick(item: TransformedWebBannerResponse, index?: number) {
    sendActivityTrackerEvent(ActivityTrackerEventType.MODULE_CLICK_ACTION, {
      moduleName: 'homepage_marketing_carousel',
      itemId: -1,
      itemClicked: item.heading.replace(/ /g, '_'),
      itemPosition: index,
      schemaVersion: '1.0',
    });
  }

  const items = webBannerData.map((item, index) => (
    <Banner
      key={index}
      title={item.heading}
      body={item.bodyJson}
      cta={{
        copy: item.ctaCopy,
        url: item.ctaHref,
        onClick: () => onClick(item, index),
      }}
      desktopBackgroundImage={item.desktopBackgroundImageUrl}
      mobileBackgroundImage={item.mobileBackgroundImageUrl}
    />
  ));

  return (
    <div
      className={clsx({
        [styles.marginTop]: isAuthenticated && !isHomepageSearchBarVisible,
      })}
    >
      <Carousel
        items={items}
        itemsPerSlide={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
        showNextPreview={false}
        showPreviousPreview={false}
        infinityMode={true}
        autoScroll={true}
        hideButtons={{
          desktop: { prev: true },
          mobile: { prev: true, next: true },
        }}
        indicators={true}
        navButtonOffsetX={16}
        itemGap={0}
      />
    </div>
  );
}
