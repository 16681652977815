import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '@/modules/storage';
import {
  MAX_PRODUCT_CARDS,
  RECENTLY_VIEWED_PRODUCTS_LOCAL_STORAGE_KEY,
} from '@/modules/recentlyViewed/constants';
import {
  LegacyLocallyStoredRecentlyViewedProduct,
  LocallyStoredRecentlyViewedProduct,
} from '@/modules/recentlyViewed/types';
import {
  Preview,
  ProductBySlugExtendedResponse,
  ProductBySlugResponse,
} from '@/modules/product/types';
import { isSold } from '@/modules/product/helpers/isSold';
import {
  picturePreviewAdapter,
  videoPreviewAdapter,
} from '@/modules/pictures/helpers.ts';
import { safeJsonParse } from '@/modules/storage/helpers';

function getProductPreview(product: ProductBySlugResponse): Preview {
  if (product.videos?.length) {
    return videoPreviewAdapter(product.videos[0]);
  }

  return product.pictures?.length
    ? picturePreviewAdapter(product.pictures[0])
    : {};
}

export function createLocallyStoredRecentlyViewedProduct(
  product: ProductBySlugResponse,
  productExtended: ProductBySlugExtendedResponse,
  overrides?: Partial<LocallyStoredRecentlyViewedProduct>
): LocallyStoredRecentlyViewedProduct {
  return {
    id: product.id,
    slug: product.slug,
    preview: getProductPreview(product),
    has_video: product?.videos ? product.videos.length > 0 : false,
    pricing: productExtended.pricing,
    sizes: productExtended.sizes?.map((size) => size.name) || [],
    status: product.status,
    sold: isSold(product.status),
    timestamp: Date.now(),
    ...overrides,
  };
}

export function getLocallyStoredRecentlyViewedProducts(): LocallyStoredRecentlyViewedProduct[] {
  const localProducts = getLocalStorageItem(
    RECENTLY_VIEWED_PRODUCTS_LOCAL_STORAGE_KEY
  );

  const parsedProducts: (
    | LocallyStoredRecentlyViewedProduct
    | LegacyLocallyStoredRecentlyViewedProduct
  )[] = safeJsonParse(localProducts, []);

  const filteredProducts = parsedProducts.filter(
    (product): product is LocallyStoredRecentlyViewedProduct =>
      'pricing' in product
  );

  /*
    When we switched to the new pricing structure there will have been some users with the old structure.
    If we detect there are less in the new format than the original we will just delete their localStorage items
  */
  if (filteredProducts.length < parsedProducts.length) {
    removeLocalStorageItem(RECENTLY_VIEWED_PRODUCTS_LOCAL_STORAGE_KEY);
    return [];
  }

  return filteredProducts;
}

export function addProductToLocalStorage(
  newProduct: LocallyStoredRecentlyViewedProduct
) {
  const localProducts = getLocallyStoredRecentlyViewedProducts();
  const filteredProducts = localProducts.filter(
    (existingProduct) => existingProduct.id !== newProduct.id
  );

  setLocalStorageItem(
    RECENTLY_VIEWED_PRODUCTS_LOCAL_STORAGE_KEY,
    JSON.stringify([newProduct, ...filteredProducts])
  );
}

export function getProductCardColumns(productsCount: number) {
  const MIN_COLUMNS = 4;

  if (productsCount < MIN_COLUMNS) {
    return MIN_COLUMNS;
  }
  if (productsCount > MAX_PRODUCT_CARDS) {
    return MAX_PRODUCT_CARDS;
  }
  return productsCount;
}
