import React from 'react';
import clsx from 'clsx';

import styles from './styles.module.css';

import { Placeholder } from '@/components/Placeholder';
import {
  DEFAULT_ITEM_WIDTH,
  ScrollableItemRow,
} from '@/components/ScrollableItemRow';

type Props = {
  itemsPerRow: number;
  productsCount: number;
  pageXPadding?: number;
  minItemWidth?: number;
  className?: string;
  attributesClassName?: string;
};

export function ModuleProductsSkeleton({
  itemsPerRow,
  pageXPadding,
  minItemWidth = DEFAULT_ITEM_WIDTH,
  productsCount,
  className,
  attributesClassName,
}: Props) {
  return (
    <ScrollableItemRow
      itemsPerRow={itemsPerRow}
      pageXPadding={pageXPadding}
      minItemWidth={minItemWidth}
      className={className}
    >
      {Array.from({ length: productsCount }).map((_, index) => (
        <div
          key={index}
          className={styles.card}
          style={{ '--minWidth': `${minItemWidth}px` } as React.CSSProperties}
        >
          <Placeholder
            shape="rectangle"
            width="100%"
            height="100%"
            className={styles.image}
          />
          <Placeholder
            shape="rectangle"
            width="64px"
            height="21px"
            className={clsx(styles.attributes, attributesClassName)}
          />
        </div>
      ))}
    </ScrollableItemRow>
  );
}
