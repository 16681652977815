'use client';

import React from 'react';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { useTranslations } from 'next-intl';

import { HOMEPAGE_MAX_PRODUCTS } from './constants.ts';
import styles from './styles.module.css';

import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker.ts';
import { useCurrentUser } from '@/modules/user/useCurrentUser.ts';
import { ModuleHeader, ModuleHeaderSize } from '@/components/ModuleHeader';
import { ExtendedLink } from '@/components/ExtendedLink';
import { appendParams } from '@/modules/routing/appendParams/appendParams.ts';
import {
  ActivityTrackerEventType,
  HomePageModuleNames,
  ModuleInteractionType,
  moduleOriginParamName,
} from '@/modules/activityTracker/constants.ts';
import { ScrollableItemRow } from '@/components/ScrollableItemRow';
import { getProductCardColumns } from '@/modules/recentlyViewed/helpers.ts';
import { SearchListProduct } from '@/modules/product/types.ts';
import { HomepageProductCard } from '@/app/[locale]/(home)/components/HomepageProductCard';
import { getProductSizeName } from '@/modules/sizes/helpers.ts';

type Props = {
  searchTerm: string;
  products: SearchListProduct[];
};

export function RecentSearchesUi({ searchTerm, products }: Props) {
  const tCommon = useTranslations('common');
  const tHome = useTranslations('home');
  const [, currentUser] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({
    userId: currentUser?.id,
  });

  const searchURL = appendParams(`/search/?q=${searchTerm}`, [
    moduleOriginParamName,
    HomePageModuleNames.RecentFromYourSearch,
  ]);

  function handleSeeMoreClick() {
    sendActivityTrackerEvent(
      ActivityTrackerEventType.MODULE_INTERACTION_ACTION,
      {
        moduleName: HomePageModuleNames.RecentFromYourSearch,
        interactionType: ModuleInteractionType.SeeMoreClicked,
        schemaVersion: '1.0',
      }
    );
  }

  function handleProductClick(
    selectedProductId: number,
    selectedProductName: string,
    selectedProductPosition: number
  ) {
    sendActivityTrackerEvent(ActivityTrackerEventType.MODULE_CLICK_ACTION, {
      moduleName: HomePageModuleNames.RecentFromYourSearch,
      itemClicked: selectedProductName,
      itemId: selectedProductId,
      itemPosition: selectedProductPosition,
      schemaVersion: '1.0',
    });
  }

  return (
    <section>
      <ModuleHeader
        title={
          <>
            {tHome('NewForYourSearch')}
            <span className={styles.searchTerm}>&quot;{searchTerm}&quot;</span>
          </>
        }
        size={ModuleHeaderSize.Large}
      >
        <ExtendedLink href={searchURL} onClick={handleSeeMoreClick}>
          <Text className={styles.seeMoreText}>{tCommon('SeeMore')}</Text>
        </ExtendedLink>
      </ModuleHeader>

      <ScrollableItemRow
        minItemWidth={144}
        itemsPerRow={getProductCardColumns(products.length)}
      >
        {products.slice(0, HOMEPAGE_MAX_PRODUCTS).map((productItem, index) => (
          <li key={productItem.id} className={styles.productListItem}>
            <HomepageProductCard
              preview={productItem.preview}
              productHref={`${productItem.slug}?moduleOrigin=${HomePageModuleNames.RecentFromYourSearch}`}
              pictures={productItem.pictures}
              pricing={productItem.pricing}
              hasVideo={productItem.has_video}
              size={
                productItem.sizes &&
                getProductSizeName(productItem.sizes, {
                  multipleSizes: tCommon('MultipleSizes'),
                })
              }
              handleClick={() =>
                handleProductClick(productItem.id, productItem.slug, index)
              }
            />
          </li>
        ))}
      </ScrollableItemRow>
    </section>
  );
}
