'use client';

import React from 'react';
import { Modal } from '@depop/web-ui-kit/Modal';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';

import styles from './styles.module.css';

import { useShowHomepageSearchBar } from '@/modules/home/hooks/useShowHomepageSearchBar';
import { useSearchBarModal } from '@/modules/search/useSearchBarModal';
import { SearchBar } from '@/components/SearchBar';
import { useShowHomepageFeaturedCategories } from '@/modules/home/hooks/useShowHomepageFeaturedCategories';

export function HomepageSearchBar() {
  const isVisible = useShowHomepageSearchBar();
  const { isOpen, setIsOpen, modalTrigger } = useSearchBarModal();
  const t = useTranslations('common');

  const isFeaturedCategoriesVisible = useShowHomepageFeaturedCategories();

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <div
        className={clsx(styles.searchBarWrapper, {
          [styles.searchBarWithMargin]: !isFeaturedCategoriesVisible,
        })}
      >
        <SearchBar
          onFocus={() => setIsOpen(true)}
          isPlaceholderAnimated={false}
          placeholder={t('SearchBar.PlaceholderShort')}
        />
      </div>
      <Modal
        isOpen={isOpen}
        setOpen={setIsOpen}
        ignoreModalFocus
        content={() => <SearchBar isAutoFocused sendATEventOnFocus={false} />}
        title={t('Search.Placeholder')}
        id="search-bar-modal"
        overlayAriaLabel={t('Search.Placeholder')}
        triggerRef={modalTrigger}
        testId="homepageSearchBar__modal"
      />
    </>
  );
}
