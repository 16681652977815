'use client';

import React from 'react';
import { useTranslations } from 'next-intl';

import styles from './styles.module.css';

import { useSearchBrandCategoryPills } from '@/modules/search/useSearchBrandCategoryPills';
import { ModuleHeader } from '@/components/ModuleHeader';
import { ExtendedLink } from '@/components/ExtendedLink';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import {
  ActivityTrackerEventType,
  HomePageModuleNames,
} from '@/modules/activityTracker/constants';

const MINIMUM_VISIBLE_PILLS = 5;

export function BrandCategoryPills() {
  const pills = useSearchBrandCategoryPills();
  const t = useTranslations('home');
  const [, currentUser] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({
    userId: currentUser?.id,
  });

  function handlePillClick(itemClicked: string, itemPosition: number) {
    sendActivityTrackerEvent(ActivityTrackerEventType.MODULE_CLICK_ACTION, {
      itemClicked,
      itemPosition,
      itemId: 0,
      moduleName: HomePageModuleNames.BrandedCategories,
      schemaVersion: '1.0',
    });
  }

  if (pills.length < MINIMUM_VISIBLE_PILLS) {
    return null;
  }

  return (
    <section>
      <ModuleHeader title={t('BrandedCategories.Title')} />
      <div className={styles.scrollableContainer}>
        <ul className={styles.pillList}>
          {pills.map((pill, index) => (
            <li key={pill.href}>
              <ExtendedLink
                href={pill.href}
                className={styles.pill}
                onClick={() => handlePillClick(pill.label, index)}
              >
                {pill.label}
              </ExtendedLink>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
