import { BuyerOfferDisplayStatus, OfferResponse } from '@/modules/offers/types';

export function getActiveOffers(offers?: OfferResponse) {
  return (
    offers?.objects?.filter((offer) =>
      [
        BuyerOfferDisplayStatus.ACCEPTED,
        BuyerOfferDisplayStatus.COUNTER_RECEIVED,
        BuyerOfferDisplayStatus.RECEIVED_PRIVATE_OFFER,
      ].includes(offer.offer_display_status)
    ) || []
  );
}
