import { getBrandCategoryListFromLocalStorage } from '@/modules/product/helpers/getBrandCategoryListFromLocalStorage.ts';
import { useBrandsBatchIds } from '@/modules/brands/hooks/useBrandsBatchIds';
import { useCategoriesById } from '@/modules/categories/useCategoriesById';
import { BrandCategoryPillAttributes } from '@/modules/search/types';
import {
  createBrandCategoryPillHref,
  getUniqueBrandIds,
} from '@/modules/search/helpers';

export function useSearchBrandCategoryPills() {
  const { data: categoriesByIdRes } = useCategoriesById();
  const categoriesById = categoriesByIdRes?.data || {};
  const brandCategoryList = getBrandCategoryListFromLocalStorage();
  const { data: brands } = useBrandsBatchIds(
    getUniqueBrandIds(brandCategoryList)
  );

  return brandCategoryList.reduce<BrandCategoryPillAttributes[]>(
    (list, brandedCategoryId) => {
      const [brandId, categoryId] = brandedCategoryId.split(':');
      const brandName = brands?.[brandId]?.name;
      const category = categoriesById[Number(categoryId)];
      const categoryName = category?.name?.toLowerCase();

      if (!brandName || !category || !categoryName) {
        return list;
      }
      const label = `${brandName} ${categoryName}`;

      list.push({
        label,
        href: createBrandCategoryPillHref({
          category,
          categoryId: Number(categoryId),
          brandId: Number(brandId),
          searchQuery: label,
        }),
      });

      return list;
    },
    []
  );
}
