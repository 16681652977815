'use client';

import React, { useState } from 'react';
import { useTranslations } from 'next-intl';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { useResponsive } from '@depop/web-ui-kit/useResponsive';

import styles from './styles.module.css';

import { SearchListProduct } from '@/modules/product/types';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { ModuleHeader, ModuleHeaderSize } from '@/components/ModuleHeader';
import {
  ActivityTrackerEventType,
  HomePageModuleNames,
  ModuleInteractionType,
} from '@/modules/activityTracker/constants';
import { ButtonMinimal } from '@/components/ButtonMinimal';
import { getProductCardColumns } from '@/modules/recentlyViewed/helpers';
import { ScrollableItemRow } from '@/components/ScrollableItemRow';
import { HomepageProductCard } from '@/app/[locale]/(home)/components/HomepageProductCard';
import { getProductSizeName } from '@/modules/sizes/helpers';
import { SuggestedForYouModal } from '@/app/[locale]/(home)/components/SuggestedForYou/SuggestedForYouModal';

type Props = {
  products: SearchListProduct[];
};

export function SuggestedForYouUi({ products }: Props) {
  const tCommon = useTranslations('common');
  const tHome = useTranslations('home');
  const { isMaxMedium } = useResponsive();
  const [_, currentUser] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({
    userId: currentUser?.id,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleProductClick(
    selectedProductId: number,
    selectedProductName: string,
    selectedProductPosition: number
  ) {
    sendActivityTrackerEvent(ActivityTrackerEventType.MODULE_CLICK_ACTION, {
      moduleName: HomePageModuleNames.SuggestedForYou,
      itemClicked: selectedProductName,
      itemId: selectedProductId,
      itemPosition: selectedProductPosition,
      schemaVersion: '1.0',
    });
  }

  function handleSeeMoreClick() {
    setIsModalOpen(true);
    sendActivityTrackerEvent(
      ActivityTrackerEventType.MODULE_INTERACTION_ACTION,
      {
        moduleName: HomePageModuleNames.SuggestedForYou,
        interactionType: ModuleInteractionType.SeeMoreClicked,
        schemaVersion: '1.0',
      }
    );
  }

  return (
    <section>
      <ModuleHeader
        title={tHome('SuggestedForYou')}
        size={ModuleHeaderSize.Large}
      >
        <ButtonMinimal onClick={handleSeeMoreClick}>
          <Text className={styles.seeMoreText}>{tCommon('SeeMore')}</Text>
        </ButtonMinimal>
      </ModuleHeader>

      <ScrollableItemRow
        minItemWidth={144}
        itemsPerRow={
          !isMaxMedium
            ? getProductCardColumns(products.length)
            : products.length
        }
      >
        {products.map((product, index) => (
          <li key={product.id}>
            <HomepageProductCard
              preview={product.preview}
              productHref={`${product.slug}?moduleOrigin=${HomePageModuleNames.SuggestedForYou}`}
              pictures={product.pictures}
              pricing={product.pricing}
              hasVideo={product.has_video}
              size={
                product.sizes &&
                getProductSizeName(product.sizes, {
                  multipleSizes: tCommon('MultipleSizes'),
                })
              }
              handleClick={() =>
                handleProductClick(product.id, product.slug, index)
              }
            />
          </li>
        ))}
      </ScrollableItemRow>

      <SuggestedForYouModal
        isOpen={isModalOpen}
        setOpen={setIsModalOpen}
        onProductClick={handleProductClick}
      />
    </section>
  );
}
