/**
 * Script to load Maze integration for user testing
 *
 * Once the script has initialised, it will allow Maze to display a prompt on the page asking users for feedback.
 *
 * When removing the script, please remember to also remove maze.co from the list of allowed domains in the generateCSP() function in our middleware.
 *
 * @see https://help.maze.co/hc/en-us/articles/9800356063891-Installing-the-Maze-snippet-on-your-website
 */
export const MazeScript = `(function(m,a,z,e){var s,t;try{t=m.sessionStorage.getItem('maze-us')}catch(err){}if(!t){t=new Date().getTime();try{m.sessionStorage.setItem('maze-us',t)}catch(err){}}s=a.createElement('script');s.src=z+'?apiKey='+e;s.async=!0;a.getElementsByTagName('head')[0].appendChild(s);m.mazeUniversalSnippetApiKey=e})(window,document,'https://snippet.maze.co/maze-universal-loader.js','dd73517a-ce8e-48cb-b295-c031eefb71e8')`;
