import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';

import styles from './styles.module.css';

type Props = PropsWithChildren<{
  onClick: () => void;
  isActive?: boolean;
}>;

export function Pill({ onClick, children, isActive = false }: Props) {
  return (
    <button
      className={clsx(styles.pill, {
        [styles.pillActive]: isActive,
      })}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
