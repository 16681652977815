'use client';

import React from 'react';

import styles from './styles.module.css';

import { Pill } from '@/components/Pill';
import { ExtendedLink } from '@/components/ExtendedLink';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import {
  ActivityTrackerEventType,
  ModuleNames,
} from '@/modules/activityTracker/constants';
import { useShowHomepageFeaturedCategories } from '@/modules/home/hooks/useShowHomepageFeaturedCategories';
import { shuffleArray } from '@/utils/shuffleArray';

export const FEATURED_CATEGORIES = [
  'Loungewear',
  'Workout',
  'Puffer jacket',
  'Denim jacket',
  'Casual outfits',
  'Party fits',
  'Sneakers',
  'Dresses',
  'Vintage gems',
];

export function FeaturedCategories() {
  const [, user] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({
    userId: user?.id,
  });
  const isVisible = useShowHomepageFeaturedCategories();

  function handleCategoryPillClick(name: string, index: number) {
    sendActivityTrackerEvent(ActivityTrackerEventType.MODULE_CLICK_ACTION, {
      moduleName: ModuleNames.HomepageFeaturedCategories,
      schemaVersion: '1.0',
      itemClicked: name,
      itemPosition: index,
      itemId: -1,
    });
  }

  if (!isVisible) {
    return null;
  }

  const shuffledPills = shuffleArray([...FEATURED_CATEGORIES]);

  return (
    <div className={styles.pillsContainer}>
      {shuffledPills.map((pill, index) => (
        <ExtendedLink
          className={styles.link}
          href={`/search?q=${pill}`}
          key={pill}
        >
          <Pill
            onClick={() => {
              handleCategoryPillClick(pill, index);
            }}
          >
            {pill}
          </Pill>
        </ExtendedLink>
      ))}
    </div>
  );
}
