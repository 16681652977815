'use client';

import React from 'react';
import { H2 } from '@depop/web-ui-kit/Typography/H2';
import { LazyLoadImage } from '@depop/web-ui-kit/LazyLoadImage';

import styles from './styles.module.css';

import { ShopByStyleData } from '@/modules/cms/helpers/assertions/shopByStyle.ts';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker.ts';
import { ModuleHeader, ModuleHeaderSize } from '@/components/ModuleHeader';
import { ExtendedLink } from '@/components/ExtendedLink';
import { appendParams } from '@/modules/routing/appendParams/appendParams.ts';
import {
  ActivityTrackerEventType,
  HomePageModuleNames,
  moduleOriginParamName,
} from '@/modules/activityTracker/constants.ts';

type Props = {
  data: ShopByStyleData;
};

const TILE_WIDTH = 408;
const TILE_HEIGHT = 204;

export function ShopByStyleUi({ data }: Props) {
  const { sendActivityTrackerEvent } = useActivityTracker();

  function sendATEvent(title: string, itemPosition: number) {
    sendActivityTrackerEvent(ActivityTrackerEventType.MODULE_CLICK_ACTION, {
      moduleName: HomePageModuleNames.ShopByStyle,
      itemClicked: title,
      itemId: -1,
      itemPosition,
      schemaVersion: '1.0',
    });
  }

  return (
    <section>
      <ModuleHeader title={data.titleCopy} size={ModuleHeaderSize.Large} />
      <div className={styles.tileContainer}>
        <ul className={styles.tileLayout}>
          {data.items.map((tile, index) => {
            const srcSet = `${
              tile.image.url
            }?w=${TILE_WIDTH}&fm=webp ${TILE_WIDTH}w, ${tile.image.url}?w=${
              TILE_WIDTH * 1.5
            }&fm=webp ${TILE_WIDTH * 2}w`;

            return (
              <ExtendedLink
                key={tile.link}
                className={styles.link}
                href={appendParams(tile.link, [
                  moduleOriginParamName,
                  HomePageModuleNames.ShopByStyle,
                ])}
                onClick={() => sendATEvent(tile.title, index)}
              >
                <div className={styles.imgOverlay}>
                  <LazyLoadImage
                    alt=""
                    srcSet={srcSet}
                    width={TILE_WIDTH}
                    height={TILE_HEIGHT}
                    src={`${tile.image.url}?w=${TILE_WIDTH * 2}&fm=webp`}
                    sizes="(max-width: 768px) 200px, (min-width: 768px) 400px"
                  />
                </div>

                <H2 className={styles.title}>{tile.title}</H2>
              </ExtendedLink>
            );
          })}
        </ul>
      </div>
    </section>
  );
}
