export function getProductSizeName(
  sizes: string[] = [],
  translations: {
    multipleSizes: string;
    size?: string;
  }
) {
  const multipleSizes = sizes.length > 1;

  const name = sizes?.[0];
  const oneSizeRegex = new RegExp(
    'one size|einheitsgröße|taglia unica|taille unique',
    'i'
  );

  if (multipleSizes) {
    return translations.multipleSizes;
  }
  if (name) {
    if (oneSizeRegex.test(name)) {
      return name;
    }
    return translations.size ? `${translations.size} ${name}` : name;
  }
  return '';
}
