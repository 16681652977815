'use client';

import { useEffect, useState } from 'react';

const GOOGLE_IDENTITY_SCRIPT_ID = 'googleIdentity';
const GOOGLE_IDENTITY_SCRIPT_SRC = 'https://accounts.google.com/gsi/client';

export function useGoogleIdentityService(nonce?: string) {
  const [isGoogleIdentityLoaded, setIsGoogleIdentityLoaded] = useState(false);

  function googleScriptLoaded() {
    setIsGoogleIdentityLoaded(true);
  }

  function googleScriptErrored() {
    setIsGoogleIdentityLoaded(false);
  }

  useEffect(() => {
    const googleScript = document.getElementById(GOOGLE_IDENTITY_SCRIPT_ID);

    if (googleScript === null) {
      try {
        const googleIdentityScript = document.createElement('script');
        googleIdentityScript.setAttribute('id', GOOGLE_IDENTITY_SCRIPT_ID);

        googleIdentityScript.src = GOOGLE_IDENTITY_SCRIPT_SRC;
        googleIdentityScript.defer = true;
        googleIdentityScript.async = true;
        googleIdentityScript.nonce = nonce;
        googleIdentityScript.addEventListener('load', googleScriptLoaded);
        googleIdentityScript.addEventListener('error', googleScriptErrored);

        document.body.appendChild(googleIdentityScript);
      } catch {
        googleScriptErrored();
      }
    } else {
      googleScript.addEventListener('load', googleScriptLoaded);
    }

    return () => {
      const googleIdentityScript = document.getElementById(
        GOOGLE_IDENTITY_SCRIPT_ID
      );
      if (googleIdentityScript !== null) {
        googleIdentityScript.removeEventListener('load', googleScriptLoaded);
        googleIdentityScript.removeEventListener('error', googleScriptErrored);
        document.body.removeChild(googleIdentityScript);
      }
    };
  }, []);

  return { isGoogleIdentityLoaded };
}
