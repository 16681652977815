'use client';

import React from 'react';
import { H2 } from '@depop/web-ui-kit/Typography/H2';
import { Button } from '@depop/web-ui-kit/Button';

import styles from './styles.module.css';

import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker.ts';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants.ts';
import { ProductPropositionData } from '@/modules/cms/helpers/assertions/productProposition.ts';
import { ExtendedLink } from '@/components/ExtendedLink';

type Props = {
  data: ProductPropositionData;
};

export function ProductPropositionUi({ data }: Props) {
  const { sendActivityTrackerEvent } = useActivityTracker();

  function handleCtaClick() {
    sendActivityTrackerEvent(ActivityTrackerEventType.MODULE_CLICK_ACTION, {
      itemClicked: 'homepage_proposition_cta',
      itemId: 1,
      itemPosition: -1,
      moduleName: 'homepage_proposition_module',
    });
  }

  return (
    <section className={styles.container}>
      <H2 className={styles.title}>{data.titleCopy}</H2>
      <ExtendedLink
        href={data.cta.url}
        onClick={handleCtaClick}
        className={styles.link}
      >
        <Button className={styles.ctaButton}>{data.cta.copy}</Button>
      </ExtendedLink>
    </section>
  );
}
