import React from 'react';
import { VideoBasicBoldIcon } from '@depop/web-ui-kit/Icons/VideoBasicBoldIcon';
import { LazyLoadImage } from '@depop/web-ui-kit/LazyLoadImage';

import styles from './styles.module.css';

import {
  ProductCard,
  Props as ProductCardProps,
} from '@/components/ProductCard';
import {
  LocallyStoredRecentlyViewedProduct,
  RecentlyViewedProduct,
} from '@/modules/recentlyViewed/types';
import { Price } from '@/components/Price';
import { getProductHoverImgSrc } from '@/modules/product/helpers/getProductHoverImgSrc.ts';

type Props = {
  product: RecentlyViewedProduct | LocallyStoredRecentlyViewedProduct;
  handleClick: (
    product: RecentlyViewedProduct | LocallyStoredRecentlyViewedProduct
  ) => void;
  moduleOrigin?: string;
};

export function RecentlyViewedProductCard({
  product,
  handleClick,
  moduleOrigin,
}: Props) {
  const hasMoreThanOnePicture = product?.pictures
    ? product.pictures.length > 1
    : false;

  const conditionalProps: Partial<ProductCardProps> = {};

  if (hasMoreThanOnePicture) {
    conditionalProps.HoverOverlay = () => (
      <LazyLoadImage
        alt=""
        className={styles.hoverImage}
        src={product.pictures && getProductHoverImgSrc(product.pictures)}
      />
    );
  }

  return (
    <ProductCard
      productHref={
        moduleOrigin
          ? `${product.slug}?moduleOrigin=${moduleOrigin}`
          : product.slug
      }
      preview={product.preview}
      handleClick={() => handleClick(product)}
      ProductAttributes={() => (
        <div className={styles.productAttributesContainer}>
          <Price
            currencyCode={product.pricing.currency_name}
            price={product.pricing.original_price.total_price}
            discountedPrice={product.pricing.discounted_price?.total_price}
            inPreview
          />
          {/* @todo - Size will go here when we add Recently Viewed to the Homepage */}
        </div>
      )}
      Overlay={() =>
        product.has_video ? (
          <div className={styles.videoIcon}>
            <VideoBasicBoldIcon />
          </div>
        ) : null
      }
      {...conditionalProps}
    />
  );
}
