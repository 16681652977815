import React from 'react';
import { Modal, ModalProps } from '@depop/web-ui-kit/Modal';
import { useTranslations } from 'next-intl';
import { LoadingBall } from '@depop/web-ui-kit/LoadingBall';

import { HomePageModuleNames } from '@/modules/activityTracker/constants';
import styles from '@/app/[locale]/(home)/components/SuggestedForYou/styles.module.css';
import { InfiniteScroll } from '@/components/InfiniteScroll';
import { HomepageProductCard } from '@/app/[locale]/(home)/components/HomepageProductCard';
import { getProductSizeName } from '@/modules/sizes/helpers';
import { useExploreStyleEditProducts } from '@/modules/explore/hooks/useExploreStyleEditProducts';
import { getProductsFromPaginatedResponse } from '@/modules/product/helpers/getProductsFromPaginatedResponse';

type Props = Pick<ModalProps, 'isOpen' | 'setOpen'> & {
  onProductClick: (
    selectedProductId: number,
    selectedProductName: string,
    selectedProductPosition: number
  ) => void;
};

export function SuggestedForYouModal({
  isOpen,
  setOpen,
  onProductClick,
}: Props) {
  const tHome = useTranslations('home');
  const tCommon = useTranslations('common');
  const { data, fetchNextPage, isFetching, hasNextPage } =
    useExploreStyleEditProducts();

  const products = getProductsFromPaginatedResponse(data);

  return (
    <Modal
      wide
      isOpen={isOpen}
      setOpen={setOpen}
      title={tHome('SuggestedForYou')}
      testId={`${HomePageModuleNames.SuggestedForYou}__modal`}
      content={() => (
        <div className={styles.modalProductListContainer}>
          <InfiniteScroll
            onEndReached={fetchNextPage}
            loading={isFetching}
            hasMore={hasNextPage}
            LoadingComponent={<LoadingBall />}
          >
            <ul className={styles.modalProductList}>
              {products.map((product, index) => (
                <li key={product.id}>
                  <HomepageProductCard
                    preview={product.preview}
                    productHref={`${product.slug}?moduleOrigin=${HomePageModuleNames.SuggestedForYou}`}
                    pictures={product.pictures}
                    pricing={product.pricing}
                    hasVideo={product.has_video}
                    size={
                      product.sizes &&
                      getProductSizeName(product.sizes, {
                        multipleSizes: tCommon('MultipleSizes'),
                      })
                    }
                    handleClick={() =>
                      onProductClick(product.id, product.slug, index)
                    }
                  />
                </li>
              ))}
            </ul>
          </InfiniteScroll>
        </div>
      )}
    />
  );
}
