export type AppleAuthConfig = {
  clientId?: string;
  redirectURI?: string;
  nonce?: string;
  scope?: string;
  usePopup?: boolean;
};

export type AppleName = {
  firstName: string;
  lastName: string;
  middleName: string;
};

export type AppleSignInResponse = {
  authorization: {
    code: string;
    id_token: string;
  };
  user: {
    email: string;
    name: AppleName;
  };
};

export enum SSOButtonVariant {
  EyeCatching = 'eyeCatching',
  Icon = 'icon',
  Standard = 'standard',
}

type CookieDetails = {
  email: string;
  firstName?: string;
  lastName?: string;
};

export type AppleCookieDetails = CookieDetails & {
  appleAccessToken: string;
  appleUserId: string;
};

export enum EmailStatus {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
}

export type EmailAvailabilityResponse = {
  email: string;
  status: EmailStatus;
};
