import {
  FETCH_RECENTLY_VIEWED_ENDPOINT,
  UPDATE_RECENTLY_VIEWED_ENDPOINT,
  DELETE_RECENTLY_VIEWED_ENDPOINT,
} from '@/constants/endpoints';
import http from '@/modules/http';
import {
  FetchRecentlyViewedRequestParams,
  FetchRecentlyViewedResponse,
  UpdateRecentlyViewedRequestBody,
} from '@/modules/recentlyViewed/types';
import { AxiosCompatibleResponse } from '@/modules/http/types';

export function fetchRecentlyViewed({
  params,
}: {
  params?: FetchRecentlyViewedRequestParams;
}): Promise<AxiosCompatibleResponse<FetchRecentlyViewedResponse>> {
  return http.get(FETCH_RECENTLY_VIEWED_ENDPOINT, {
    params,
    withAuth: true,
  });
}

export function updateRecentlyViewed({
  userId,
  requestBody,
}: {
  userId: number;
  requestBody: UpdateRecentlyViewedRequestBody;
}) {
  return http.put(
    UPDATE_RECENTLY_VIEWED_ENDPOINT.replace(':userId', String(userId)),
    requestBody,
    {
      withAuth: true,
    }
  );
}

export function clearRecentlyViewed(userId: number) {
  return http.delete(
    DELETE_RECENTLY_VIEWED_ENDPOINT.replace(':userId', String(userId)),
    {
      withAuth: true,
    }
  );
}
