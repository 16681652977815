import { useQuery } from '@tanstack/react-query';

import { fetchBrandsBatchIds } from '../api';

import { useSessionId } from '@/modules/storage/useSessionId';
import { RQ_BRANDS_BATCH_BY_ID_KEY } from '@/modules/ReactQuery/cacheKeys';

export function useBrandsBatchIds(brandIds: string[] | number[] = []) {
  const sessionId = useSessionId();

  return useQuery({
    queryKey: [`${RQ_BRANDS_BATCH_BY_ID_KEY}`, brandIds],
    queryFn: async () => {
      const res = await fetchBrandsBatchIds({
        brandIds,
        meta: { sessionId: sessionId || '' },
      });
      return res?.data;
    },
    enabled: brandIds.length > 0,
  });
}
