'use client';

import React, { useEffect } from 'react';
import { useTranslations } from 'next-intl';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { useInView } from 'react-intersection-observer';

import styles from './styles.module.css';

import { ModuleHeader, ModuleHeaderSize } from '@/components/ModuleHeader';
import { useRecentlyViewed } from '@/modules/recentlyViewed/hooks/useRecentlyViewed';
import { getProductCardColumns } from '@/modules/recentlyViewed/helpers.ts';
import { ScrollableItemRow } from '@/components/ScrollableItemRow';
import { RecentlyViewedProductCard } from '@/components/RecentlyViewed/RecentlyViewedProductCard';
import { useRecentlyViewedTracking } from '@/modules/recentlyViewed/hooks/useRecentlyViewedTracking';
import { MAX_PRODUCT_CARDS } from '@/modules/recentlyViewed/constants';
import { RecentlyViewedModal } from '@/components/RecentlyViewed/RecentlyViewedModal';
import { ModuleProductsSkeleton } from '@/components/ModuleProductsSkeleton';

type Props = {
  productId?: number;
  minimumProductsToRender?: number;
  moduleOrigin?: string;
  moduleHeaderSize?: ModuleHeaderSize;
} & React.HTMLAttributes<HTMLDivElement>;

export function RecentlyViewed({
  title,
  productId,
  minimumProductsToRender = 0,
  moduleOrigin,
  moduleHeaderSize = ModuleHeaderSize.Small,
  ...rest
}: Props) {
  const tCommon = useTranslations('common');

  const {
    hasClearedProducts,
    isClearAllVisible,
    onModuleCtaClick,
    onModalClearAllClick,
    products,
    isError,
    isLoading,
    isModalOpen,
    setIsModalOpen,
  } = useRecentlyViewed(productId);
  const { sendModuleClickAction, sendModuleView } =
    useRecentlyViewedTracking(productId);
  const { ref: sectionRef, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const hasProducts = products.length > 0;
  const hasMinimumProductsRequirement =
    products.length >= minimumProductsToRender;
  const showModuleCta = hasMinimumProductsRequirement && !isLoading && !isError;
  const placeholderColumns = getProductCardColumns(MAX_PRODUCT_CARDS);

  useEffect(() => {
    if (inView) {
      sendModuleView();
    }
  }, [inView]);

  function getShouldRender() {
    if (isLoading) {
      return true;
    }
    if (hasClearedProducts) {
      return false;
    }
    return hasProducts && hasMinimumProductsRequirement;
  }

  if (!getShouldRender()) {
    return <></>;
  }

  return (
    <section {...rest} ref={sectionRef}>
      <ModuleHeader title={tCommon('RecentlyViewed')} size={moduleHeaderSize}>
        {showModuleCta && (
          <Text
            as="button"
            className={styles.ctaButton}
            onClick={onModuleCtaClick}
          >
            {isClearAllVisible ? tCommon('ClearAll') : tCommon('SeeMore')}
          </Text>
        )}
      </ModuleHeader>
      {isLoading && (
        <ModuleProductsSkeleton
          minItemWidth={144}
          itemsPerRow={placeholderColumns}
          productsCount={placeholderColumns}
        />
      )}
      {hasProducts && (
        <ScrollableItemRow
          minItemWidth={144}
          itemsPerRow={getProductCardColumns(products.length)}
        >
          {products.slice(0, MAX_PRODUCT_CARDS).map((product, index) => (
            <li key={product.slug} className={styles.productListItem}>
              <RecentlyViewedProductCard
                product={product}
                handleClick={() =>
                  sendModuleClickAction(product.id, product.slug, index)
                }
                moduleOrigin={moduleOrigin}
              />
            </li>
          ))}
        </ScrollableItemRow>
      )}
      <RecentlyViewedModal
        isOpen={isModalOpen}
        setOpen={setIsModalOpen}
        title={tCommon('RecentlyViewed')}
        onClearAllClick={onModalClearAllClick}
        productId={productId}
        moduleOrigin={moduleOrigin}
      />
    </section>
  );
}
