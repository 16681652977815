import { useInfiniteQuery } from '@tanstack/react-query';

import { exploreStyleEditInfiniteQueryOptions } from '@/modules/explore/queries';
import { useCurrentLocation } from '@/modules/location/useCurrentLocation';
import { useCurrentLanguage } from '@/modules/language/useCurrentLanguage';
import { SupportedLocation } from '@/modules/location/constants';
import { SUGGESTED_FOR_YOU_LIMIT } from '@/app/[locale]/(home)/components/SuggestedForYou/constants';
import { useExperiments } from '@/modules/experiments/useExperiments';
import { VARIANT_IDENTIFIER } from '@/modules/experiments/config';

export function useExploreStyleEditProducts() {
  const { location } = useCurrentLocation();
  const { language } = useCurrentLanguage();
  const { web_upfront_fees } = useExperiments(['web_upfront_fees']);

  return useInfiniteQuery(
    exploreStyleEditInfiniteQueryOptions({
      country: (location || SupportedLocation.GB) as SupportedLocation,
      lang: language || 'en',
      limit: SUGGESTED_FOR_YOU_LIMIT,
      force_fee_calculation: web_upfront_fees === VARIANT_IDENTIFIER,
    })
  );
}
