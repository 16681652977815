import React from 'react';
import clsx from 'clsx';
import { H2 } from '@depop/web-ui-kit/Typography/H2';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';

export enum ModuleHeaderSize {
  Large = 'large',
  Small = 'small',
}

type Props = {
  title?: string | React.ReactNode;
  subtitle?: string;
  children?: React.ReactNode;
  size?: ModuleHeaderSize;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'title'>;

export function ModuleHeader({
  title,
  subtitle,
  children,
  size = ModuleHeaderSize.Large,
  className = '',
  ...rest
}: Props) {
  const isLarge = size === ModuleHeaderSize.Large;
  const isSmall = size === ModuleHeaderSize.Small;

  return (
    <div
      {...rest}
      className={clsx({
        [className]: Boolean(className),
        [styles.header]: true,
        [styles['header--large']]: isLarge,
        [styles['header--small']]: isSmall,
      })}
    >
      <div>
        <H2
          className={clsx({
            [styles.title]: true,
            [styles['title--large']]: isLarge,
          })}
        >
          {title}
        </H2>
        {subtitle && (
          <Text
            className={clsx({
              [styles['subtitle--large']]: isLarge,
              [styles['subtitle--small']]: isSmall,
            })}
          >
            {subtitle}
          </Text>
        )}
      </div>
      {children}
    </div>
  );
}
