import { useEffect } from 'react';
import { useResponsive } from '@depop/web-ui-kit/useResponsive';
import { usePathname, useSearchParams } from 'next/navigation';

import { useExperiments } from '@/modules/experiments/useExperiments';
import { VARIANT_IDENTIFIER } from '@/modules/experiments/config';

export function useShowHomepageFeaturedCategories() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { isMaxMedium: isMobile } = useResponsive();
  const [{ web_6390_highlight_categories_on_homepage }, bucketFn] =
    useExperiments(['web_6390_highlight_categories_on_homepage'], {
      deferred: true,
    });

  const isHomepage = pathname === '/';
  const isFromYouTube = searchParams.get('utm_source') === 'YouTube';
  const isExperimentVisible = isHomepage && isFromYouTube && isMobile;

  useEffect(() => {
    if (isExperimentVisible) {
      bucketFn();
    }
  }, [isExperimentVisible]);

  return (
    web_6390_highlight_categories_on_homepage === VARIANT_IDENTIFIER &&
    isExperimentVisible
  );
}
