import { checkEmailAvailability } from './api';
import { GENERIC_ERROR } from './constants';

import { getLogger } from '@/modules/observability/logging';

export async function getEmailAvailabilityStatus(
  email: string,
  onError: (errorMessage: string) => void
) {
  let status;
  try {
    ({ status } = (await checkEmailAvailability(email)).data);
  } catch (error) {
    getLogger().error('Unable to fetch email availability', { error });
    return onError(GENERIC_ERROR);
  }

  return status;
}
