'use client';

import React, { CSSProperties } from 'react';
import { pascalCase } from 'text-pascal-case';
import { H1 } from '@depop/web-ui-kit/Typography/H1';

import styles from './styles.module.css';

import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { ModuleHeader, ModuleHeaderSize } from '@/components/ModuleHeader';
import { ShopByPriceTileType } from '@/modules/home/types';
import { ExtendedLink } from '@/components/ExtendedLink';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import {
  ActivityTrackerEventType,
  moduleOriginParamName,
} from '@/modules/activityTracker/constants';
import { appendParams } from '@/modules/routing/appendParams/appendParams';

type Props = {
  categories: ShopByPriceTileType[];
  moduleName: string;
  campaignTitle?: string;
};

export function ShopByPrice({ campaignTitle, categories, moduleName }: Props) {
  // NB currently this module is NOT powered by the CMS
  // but hard coded here /containers/Home/constants/shopByPrice.ts
  const [, currentUser] = useCurrentUser();
  const userId = currentUser?.id;
  const { sendActivityTrackerEvent: sendATEvent } = useActivityTracker({
    userId,
  });

  function handleClick(category: ShopByPriceTileType, itemPosition: number) {
    const params = {
      moduleName: 'shop_by_price',
      itemClicked: pascalCase(category.title).concat(' ', category.price),
      itemId: -1,
      itemPosition,
      schemaVersion: '1.0',
    };

    sendATEvent(ActivityTrackerEventType.MODULE_CLICK_ACTION, params);
  }

  return (
    <>
      <ModuleHeader title={campaignTitle} size={ModuleHeaderSize.Large} />
      <ul className={styles.tileLayout}>
        {categories.map((category: ShopByPriceTileType, index: number) => {
          return (
            <li className={styles.tileWrapper} key={index}>
              <ExtendedLink
                passHref
                href={appendParams(category.link, [
                  moduleOriginParamName,
                  moduleName,
                ])}
                legacyBehavior
              >
                {
                  //eslint-disable-next-line jsx-a11y/click-events-have-key-events
                  <a
                    role="link"
                    tabIndex={0}
                    onClick={() => {
                      handleClick(category, index);
                    }}
                  >
                    <>
                      <div
                        className={styles.background}
                        style={
                          {
                            '--background': category.backgroundStyle,
                          } as CSSProperties
                        }
                      >
                        <div className={styles.titleWrapper}>
                          <H1 className={styles.title}>{category.title}</H1>
                          <H1 className={styles.price}>{category.price}</H1>
                        </div>
                      </div>
                    </>
                  </a>
                }
              </ExtendedLink>
            </li>
          );
        })}
      </ul>
    </>
  );
}
