export const PRODUCT_INTERACTION_TYPES = {
  SIMILAR_ITEMS: 'ProductSimilarItems',
  SIMILAR_ITEMS_SASH: 'ProductSimilarItemsSash',
  SELLER_ITEMS: 'ProductSellerItems',
  SELLER_SEE_MORE: 'ProductSellerSeeMore',
  PRODUCT_LIKE: 'ProductLike',
  PRODUCT_UNLIKE: 'ProductUnlike',
  PRODUCT_SAVE: 'ProductSave',
  PRODUCT_UNSAVE: 'ProductUnsave',
};

export const PRODUCT_BRANDED_CATEGORIES_LOCAL_STORAGE_KEY = 'brandedCategories';
