import { LoginType } from '../login/types';

export enum SignupType {
  Apple = 'Apple',
  Email = 'Email',
  Google = 'Google',
  MagicLink = 'MagicLink',
}

export enum SignupSubmitTrackingLoginType {
  Apple = LoginType.Apple,
  Email = LoginType.UsernameEmail,
  Google = LoginType.Google,
  MagicLink = LoginType.MagicLink,
}

export type UserSignupDetails = {
  firstName: string;
  lastName?: string;
  username: string;
  email: string;
  password?: string;
  marketingConsent: boolean;
  country: {
    value: string;
    label: string;
  };
  googleIdToken?: string;
  appleUserId?: string;
  appleAccessToken?: string;
};

export type CheckPasswordDetails = {
  password: string;
  email?: string;
  username?: string;
};

export type VerifyPhoneNumberRequest = {
  country_code: string;
  phone_number: string;
};

export type VerifyPhoneNumberResponse = {
  verification_id: string;
  is_verified: string;
};

export type VerifyPhoneNumberConfirmRequest = {
  verification_id: string;
  sms_code: string;
};

export type SessionStoragePhoneInfo = {
  verificationId: string;
  phoneNumber: string;
  phonePrefix: string;
  countryCode: string;
};

export type SignupRequest = {
  userDetails: UserSignupDetails;
  verificationId?: string;
  magicLinkToken?: string;
};

export type SignupResponse = {
  token: string;
  publicId: string;
};

export type SignupErrorResponse = {
  error: string;
  description: string;
};

export enum SignupErrorTypes {
  UnknownError = 'unknown',
  Email = 'email',
  Username = 'username',
  Password = 'password',
  Phone = 'phone',
  OtherError = 'other',
}

export type SignupErrorDetails = {
  errorType: SignupErrorTypes;
  errorMessage: string;
};
