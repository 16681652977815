'use client';

import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { ButtonLink } from '@depop/web-ui-kit/ButtonLink';

import styles from './styles.module.css';
import { DepopEditModal } from './DepopEditModal';

import { SearchListProduct } from '@/modules/product/types';
import { ProductCard } from '@/components/ProductCard';
import { Price } from '@/components/Price';
import { getProductHoverImgSrc } from '@/modules/product/helpers/getProductHoverImgSrc';
import { ModuleHeader } from '@/components/ModuleHeader';
import { useTracking } from '@/modules/depopEdit/useTracking';
import { HomePageModuleNames } from '@/modules/activityTracker/constants';

type DepopEditUiProps = {
  products: SearchListProduct[];
  title: string;
  subtitle: string;
};

type HoverOverlayProps = {
  imgSrc?: string;
  pricing: SearchListProduct['pricing'];
  sizes: SearchListProduct['sizes'];
};

function HoverOverlay({ imgSrc, pricing, sizes }: HoverOverlayProps) {
  return (
    <div className={styles.overlayContainer}>
      {imgSrc && <img src={imgSrc} alt="" />}
      <div className={styles.attributesContainer}>
        {sizes && (
          <p className={clsx(styles.attributes, styles.size)}>{sizes[0]}</p>
        )}
        <Price
          price={pricing.original_price.total_price}
          currencyCode={pricing.currency_name}
          discountedPrice={pricing.discounted_price?.total_price}
          className={clsx(styles.attributes, styles.price)}
        />
      </div>
    </div>
  );
}

export function DepopEditUi({ products, title, subtitle }: DepopEditUiProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const tCommon = useTranslations('common');
  const { sendModuleClickAction, sendModuleInteractionAction } = useTracking();

  function handleSeeMoreClick() {
    setIsModalOpen(true);
    sendModuleInteractionAction();
  }

  return (
    <section>
      <ModuleHeader title={title} subtitle={subtitle}>
        <ButtonLink className={styles.seeMore} onClick={handleSeeMoreClick}>
          {tCommon('SeeMore')}
        </ButtonLink>
      </ModuleHeader>
      <ul className={styles.productListContainer}>
        {products.map(
          ({ id, slug, preview, pictures, pricing, sizes }, index) => {
            return (
              <li key={id}>
                <ProductCard
                  productHref={`${slug}?moduleOrigin=${HomePageModuleNames.DepopEdit}`}
                  preview={preview}
                  handleClick={() => sendModuleClickAction(slug, id, index)}
                  HoverOverlay={() => (
                    <HoverOverlay
                      imgSrc={pictures ? getProductHoverImgSrc(pictures) : ''}
                      pricing={pricing}
                      sizes={sizes}
                    />
                  )}
                />
              </li>
            );
          }
        )}
      </ul>
      <DepopEditModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={title}
      />
    </section>
  );
}
