import { useEffect } from 'react';
import { useResponsive } from '@depop/web-ui-kit/useResponsive';
import { useSelector } from 'react-redux';

import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useGoogleIdentityService } from '@/modules/auth/useGoogleIdentityService';
import { isPreviewEnv } from '@/modules/env/isPreviewEnv';
import { initGoogleIdentityClient } from '@/modules/auth/google';
import { decodeJWT } from '@/modules/auth/decodeJWT';
import { useSSOSignup } from '@/modules/signup/hooks/useSSOSignup';
import { SignupType } from '@/modules/signup/types';
import { useExtendedRouter } from '@/modules/routing/useExtendedRouter';
import { useRedirectQueryParam } from '@/modules/auth/useRedirectQueryParam';
import { RootState } from '@/modules/redux/stores/storeClient';
import { useLoginMutations } from '@/modules/login/useLoginMutations';
import { LoginType } from '@/modules/login/types';

type Params = {
  nonce: string;
};

export function useGoogleSignInPrompt({ nonce }: Params) {
  const redirectQueryParam = useRedirectQueryParam();
  const router = useExtendedRouter();
  const [isAuthenticated] = useCurrentUser();
  const { isMaxSmall } = useResponsive();
  const { isGoogleIdentityLoaded } = useGoogleIdentityService(nonce);
  const { signupWithSSO } = useSSOSignup({
    setError: () => {
      router.push(`/login/${redirectQueryParam}`);
    },
    ssoType: SignupType.Google,
  });
  const { loginMutation } = useLoginMutations({
    loginType: LoginType.GoogleSignup,
  });
  const isCookieBannerOpen = useSelector(
    (state: RootState) => state.cookies.cookieBanner.showBanner
  );

  function proceedWithGoogleSignup(googleIdToken: string) {
    const decodedUserInfo = decodeJWT(googleIdToken);
    const { email, given_name, family_name } = decodedUserInfo;

    signupWithSSO({
      name: {
        firstName: given_name,
        lastName: family_name,
      },
      email,
      credentials: { googleIdToken },
    });
  }

  async function handleGoogleIdentityResponse({
    credential: googleIdToken,
  }: google.accounts.id.CredentialResponse) {
    try {
      await loginMutation.mutateAsync({ googleIdToken });
    } catch (error: unknown) {
      // @ts-ignore
      if (error?.response) {
        proceedWithGoogleSignup(googleIdToken);
      }
    }
  }

  useEffect(() => {
    if (isGoogleIdentityLoaded && !isPreviewEnv() && !isAuthenticated) {
      if (!isMaxSmall) {
        initGoogleIdentityClient(handleGoogleIdentityResponse);
        window.google?.accounts.id.prompt();
      }
      if (isMaxSmall && !isCookieBannerOpen) {
        initGoogleIdentityClient(handleGoogleIdentityResponse);
        window.google?.accounts.id.prompt();
      }
    }
  }, [isGoogleIdentityLoaded, isAuthenticated, isMaxSmall, isCookieBannerOpen]);
}
