'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';
import { HOMEPAGE_MAX_VISIBLE_SAVED } from './constants';

import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { ModuleHeader, ModuleHeaderSize } from '@/components/ModuleHeader';
import {
  ActivityTrackerEventType,
  HomePageModuleNames,
  ModuleInteractionType,
  moduleOriginParamName,
} from '@/modules/activityTracker/constants';
import { SavedProduct } from '@/modules/saves/types';
import { ExtendedLink } from '@/components/ExtendedLink';
import { appendParams } from '@/modules/routing/appendParams/appendParams';
import { getProductCardColumns } from '@/modules/recentlyViewed/helpers';
import { ScrollableItemRow } from '@/components/ScrollableItemRow';
import { HomepageProductCard } from '@/app/[locale]/(home)/components/HomepageProductCard';
import { getProductSizeName } from '@/modules/sizes/helpers';

type Props = {
  products: SavedProduct[];
};

export function SavesUI({ products }: Props) {
  const tCommon = useTranslations('common');
  const [_, currentUser] = useCurrentUser();

  const { sendActivityTrackerEvent } = useActivityTracker({
    userId: currentUser?.id,
  });

  const isSeeMoreButtonVisible = products.length > HOMEPAGE_MAX_VISIBLE_SAVED;

  function handleProductClick(
    selectedProductId: number,
    selectedProductName: string,
    selectedProductPosition: number
  ) {
    sendActivityTrackerEvent(ActivityTrackerEventType.MODULE_CLICK_ACTION, {
      moduleName: HomePageModuleNames.Saves,
      itemClicked: selectedProductName,
      itemId: selectedProductId,
      itemPosition: selectedProductPosition,
      schemaVersion: '1.0',
    });
  }

  function handleSeeMoreClick() {
    sendActivityTrackerEvent(
      ActivityTrackerEventType.MODULE_INTERACTION_ACTION,
      {
        moduleName: HomePageModuleNames.Saves,
        interactionType: ModuleInteractionType.SeeMoreClicked,
        schemaVersion: '1.0',
      }
    );
  }

  return (
    <section>
      <ModuleHeader title={tCommon('MySaves')} size={ModuleHeaderSize.Large}>
        {isSeeMoreButtonVisible && (
          <ExtendedLink
            href={appendParams(`${currentUser?.username}/saved/`, [
              moduleOriginParamName,
              HomePageModuleNames.Saves,
            ])}
            onClick={handleSeeMoreClick}
          >
            <Text className={styles.seeMoreText}>{tCommon('SeeMore')}</Text>
          </ExtendedLink>
        )}
      </ModuleHeader>

      <ScrollableItemRow
        minItemWidth={144}
        itemsPerRow={getProductCardColumns(products.length)}
      >
        {products
          .slice(0, HOMEPAGE_MAX_VISIBLE_SAVED)
          .map((productItem, index) => (
            <li key={productItem.id} className={styles.productListItem}>
              <HomepageProductCard
                preview={productItem.preview}
                productHref={`${productItem.slug}?moduleOrigin=${HomePageModuleNames.Saves}`}
                pictures={productItem.pictures}
                pricing={productItem.pricing}
                hasVideo={productItem.has_video}
                size={
                  productItem.sizes &&
                  getProductSizeName(productItem.sizes, {
                    multipleSizes: tCommon('MultipleSizes'),
                  })
                }
                handleClick={() =>
                  handleProductClick(productItem.id, productItem.slug, index)
                }
              />
            </li>
          ))}
      </ScrollableItemRow>
    </section>
  );
}
