'use client';

import { useSearchParams } from 'next/navigation';

export function useRedirectQueryParam() {
  const searchParams = useSearchParams();
  const redirect = searchParams.get('redirect') || '';

  if (!redirect) {
    return '';
  }

  return `?redirect=${redirect}`;
}
