export function initGoogleIdentityClient(
  callback?: (response: google.accounts.id.CredentialResponse) => void
) {
  if (window.google) {
    window.google.accounts.id.initialize({
      client_id: process.env.NEXT_PUBLIC_GOOGLE_API_CLIENT_ID as string,
      callback,
    });
  }
}
