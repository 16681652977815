'use client';

import React, { RefObject, useCallback, useEffect, useRef } from 'react';
import { useTranslations } from 'next-intl';
import { H1 } from '@depop/web-ui-kit/Typography/H1';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { Modal as BackpackModal } from '@depop/web-ui-kit/Modal';

import styles from './styles.module.css';

import { useRecentlyViewedTracking } from '@/modules/recentlyViewed/hooks/useRecentlyViewedTracking';
import { RecentlyViewedModalProducts } from '@/components/RecentlyViewed/RecentlyViewedModal/RecentlyViewedModalProducts';
import { useRecentlyViewedProducts } from '@/modules/recentlyViewed/hooks/useRecentlyViewedProducts';

type Props = {
  isOpen: boolean;
  onClearAllClick: () => void;
  productId?: number;
  setOpen: (open: boolean) => void;
  title: string;
  triggerRef?: RefObject<HTMLButtonElement | null>;
  moduleOrigin?: string;
};

const PAGE_SIZE = 48;

export function RecentlyViewedModal({
  isOpen,
  onClearAllClick,
  productId,
  setOpen,
  title,
  triggerRef,
  moduleOrigin,
}: Props) {
  const tCommon = useTranslations('common');
  const { sendRecentlyViewedItemsInteractionAction, sendModuleScrollAction } =
    useRecentlyViewedTracking(productId);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const {
    products,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useRecentlyViewedProducts(productId, PAGE_SIZE);

  // We want to attach scroll listeners on the modal's <aside> element down
  // below in the useEffect block. However, due to the order of operations,
  // refs aren't populated until after the component has been rendered. So this
  // callback executes and populates the ref when the modal is rendered, and then
  // the scroll listeners are attached to the updated ref.
  //
  // See this Medium post for more info:
  // https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
  const containerRefCallback = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        containerRef.current = node;
      }
    },
    [isOpen]
  );

  function handleClose() {
    sendRecentlyViewedItemsInteractionAction(
      'RecentlyViewedItemsModuleModalClosed'
    );
  }

  useEffect(() => {
    const containerEl = containerRef.current;
    const scrollableContentEl = containerEl?.closest(
      'aside[aria-modal="true"]'
    );

    function handleScroll() {
      sendModuleScrollAction();

      scrollableContentEl?.removeEventListener('scroll', handleScroll);
    }

    if (scrollableContentEl && isOpen) {
      scrollableContentEl.addEventListener('scroll', handleScroll);

      return () => {
        scrollableContentEl?.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isOpen]);

  return (
    <BackpackModal
      isOpen={isOpen}
      setOpen={setOpen}
      triggerRef={triggerRef}
      onCloseModal={handleClose}
      wide
      customOverrides={{
        header: (
          <div>
            <H1 className={styles.title}>{title}</H1>
            <Text
              as="button"
              className={styles.ctaButton}
              onClick={onClearAllClick}
            >
              {tCommon('ClearAll')}
            </Text>
          </div>
        ),
      }}
      content={() => (
        <div className={styles.content} ref={containerRefCallback}>
          <RecentlyViewedModalProducts
            productId={productId}
            isLoading={isLoading}
            isError={isError}
            onEndReached={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            hasMore={hasNextPage}
            products={products}
            moduleOrigin={moduleOrigin}
          />
        </div>
      )}
    />
  );
}
