import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { removeLocalStorageItem } from '@/modules/storage';
import {
  MAX_PRODUCT_CARDS,
  RECENTLY_VIEWED_PRODUCTS_LOCAL_STORAGE_KEY,
} from '@/modules/recentlyViewed/constants';
import { useRecentlyViewedTracking } from '@/modules/recentlyViewed/hooks/useRecentlyViewedTracking';
import { useRecentlyViewedProducts } from '@/modules/recentlyViewed/hooks/useRecentlyViewedProducts';
import { clearRecentlyViewed } from '@/modules/recentlyViewed/api';

export function useRecentlyViewed(productId?: number) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasClearedProducts, setHasClearedProducts] = useState(false);
  const [_, currentUser] = useCurrentUser();
  const { sendInteractionAction, sendModuleInteractionAction } =
    useRecentlyViewedTracking(productId);
  const { products, isLoading, isError } = useRecentlyViewedProducts(productId);

  const isClearAllVisible = products.length <= MAX_PRODUCT_CARDS;

  const clearRecentlyViewedMutation = useMutation({
    mutationFn: (userId: number) => clearRecentlyViewed(userId),
  });

  function deleteRecentlyViewedProducts() {
    if (currentUser?.id) {
      clearRecentlyViewedMutation.mutate(currentUser.id);
    } else {
      removeLocalStorageItem(RECENTLY_VIEWED_PRODUCTS_LOCAL_STORAGE_KEY);
    }

    sendInteractionAction('RecentlyViewedItemsModuleClearAllClicked');
  }

  function onSeeMoreClick() {
    sendModuleInteractionAction();
    setIsModalOpen(true);
  }

  function onClearAllClick() {
    setHasClearedProducts(true);
    deleteRecentlyViewedProducts();
  }

  function onModuleCtaClick() {
    if (isClearAllVisible) {
      onClearAllClick();
    } else {
      onSeeMoreClick();
    }
  }

  function onModalClearAllClick() {
    setIsModalOpen(false);
    onClearAllClick();
  }

  return {
    hasClearedProducts,
    isClearAllVisible,
    isError,
    isLoading,
    isModalOpen,
    onModuleCtaClick,
    onModalClearAllClick,
    products,
    setIsModalOpen,
  };
}
