'use client';

import React from 'react';
import { LoadingBall } from '@depop/web-ui-kit/LoadingBall';
import { Modal } from '@depop/web-ui-kit/Modal';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';

import { InfiniteScroll } from '@/components/InfiniteScroll';
import { Price } from '@/components/Price';
import { ProductCard } from '@/components/ProductCard';
import { useExploreProducts } from '@/modules/explore/hooks/useExploreProducts';
import { getProductsFromPaginatedResponse } from '@/modules/product/helpers/getProductsFromPaginatedResponse';
import { useTracking } from '@/modules/depopEdit/useTracking';

type DepopEditModalProps = {
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  title: string;
};

function Content() {
  const { data, fetchNextPage, isFetching } = useExploreProducts();
  const { sendModuleClickAction } = useTracking();
  const products = getProductsFromPaginatedResponse(data);
  const end = data?.pages[0].data.meta?.end;

  return (
    <div className={styles.modalContainer}>
      <InfiniteScroll
        onEndReached={fetchNextPage}
        hasMore={!end}
        LoadingComponent={<LoadingBall />}
        loading={isFetching}
      >
        <ul className={styles.productListModalContainer}>
          {products.map(({ id, slug, preview, pricing, sizes }, index) => {
            return (
              <li key={id}>
                <ProductCard
                  productHref={`/${slug}?moduleOrigin=depop_edit`}
                  preview={preview}
                  handleClick={() => sendModuleClickAction(slug, id, index)}
                  ProductAttributes={() => (
                    <div className={styles.productAttributesModalContainer}>
                      <Price
                        currencyCode={pricing.currency_name}
                        price={pricing.original_price.total_price}
                        discountedPrice={pricing.discounted_price?.total_price}
                        inPreview
                      />
                      {sizes && <Text>{sizes[0]}</Text>}
                    </div>
                  )}
                />
              </li>
            );
          })}
        </ul>
      </InfiniteScroll>
    </div>
  );
}

export function DepopEditModal({
  isOpen,
  setIsOpen,
  title,
}: DepopEditModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      setOpen={setIsOpen}
      content={() => <Content />}
      title={title}
      wide
    />
  );
}
