import React from 'react';
import clsx from 'clsx';

import styles from './styles.module.css';

type Props = {
  itemsPerRow: number;
  className?: string;
  minItemWidth?: number;
  children: React.ReactNode;
  pageXPadding?: number;
};

export const DEFAULT_ITEM_WIDTH = 100;

export function ScrollableItemRow({
  itemsPerRow,
  className,
  minItemWidth = DEFAULT_ITEM_WIDTH,
  children,
  pageXPadding = 16,
}: Props) {
  return (
    <ul
      className={clsx([className, styles.scrollableRow])}
      style={
        {
          '--paddingRight': `${pageXPadding}px`,
          '--marginRight': `-${pageXPadding}px`,
          '--itemsPerRow': itemsPerRow,
          '--minItemWidth': `${minItemWidth}px`,
        } as React.CSSProperties
      }
    >
      {children}
    </ul>
  );
}
